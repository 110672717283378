#landing {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media (max-width: 768px) {
        margin-top: 32px;
    }

    .watch-pres-btn {
        cursor: pointer;
        color: #3e435d;
        font-size: 24px;
        font-weight: 600;
    }

    .title {
        font-size: 53px;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: -0.699999988079071px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 26.5px;
            line-height: unset;
        }
    }

    .subtitle-yellow {
        color: #ffc107;
    }

    .email-registration-input {
        padding: 12px 19px;
    }

    .email-registration-btn {
        padding: 12px 19px 12px 48px;
    }

    .invalid {
        border-color: #dc3545;
    }
}
