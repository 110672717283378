@import '~bootstrap/scss/bootstrap';

@import 'fonts';

html body {
    font-family: 'Poppins', sans-serif;
    --bs-border-color: #3e435d;
    
    #root {
        #route-content {
            padding-bottom: 48px;
        }
    }

    .btn-primary {
        --bs-btn-bg: #3e435d;
        --bs-btn-disabled-bg: #3e435d;
        --bs-btn-disabled-border-color: #3e435d;
        --bs-btn-border-color: #3e435d;
        --bs-btn-color: white;
        --bs-btn-hover-bg: #3e435def;
        --bs-btn-hover-border-color: #3e435def;
        --bs-btn-active-bg: #2d3144;
        --bs-btn-active-border-color: #2d3144;
        --bs-btn-padding-x: 19px;
        --bs-btn-padding-y: 12px;
    }
}
